.fc .fc-media-screen .fc-direction-ltr .fc-theme-standard {
    margin: 10px !important;
    max-height: 500px;
    @apply m-4 
}

.fc.fc-media-screen.fc-direction-ltr.fc-theme-standard {
  max-height: 600px !important;
}

.fc .fc-daygrid-day.fc-day-today {
    @apply bg-white
}

.fc .fc-scrollgrid {
    @apply rounded shadow
}

.fc-scrollgrid  .fc-scrollgrid-liquid {
    @apply border-white
}

.fc .fc-daygrid-day-frame {
    /* position: relative;
    height: 100%;
    width: 100%;
    display: -webkit-flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-basis: auto; */
    margin: auto;
    text-align: center;
    width: 100%;
    height: 100%;
    /* @apply 
    items-center 
    justify-center */
    
}

.fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
  margin: auto;
  text-align: center;
  width: 100%;
  height: 100%;
  position: relative;
  min-height: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fc .fc-col-header-cell-cushion {
    @apply 
    bg-white 
    border-white
    text-base 
    font-medium 
    text-center 
    text-gray-800 
    dark:text-gray-100    
  }

  .fc .fc-toolbar-title {
      @apply text-xl font-bold leading-tight text-gray-900
  }
  
  .fc-theme-standard td, .fc-theme-standard th {
      @apply border-white
  }

  .fc-scrollgrid-sync-table tr {
      @apply h-14
  }

  .fc .fc-button-primary:disabled {
    @apply bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow
  }

  .fc .fc-button:not(:disabled) {
    @apply bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow
  }

  .fc .fc-button-group > .fc-button {
    @apply bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow
  }

  .fc .fc-button-primary {
      @apply flex justify-center items-center h-8 px-4 text-sm transition-colors duration-150 rounded-lg
  }

  .fc-toolbar-chunk {
    @apply flex justify-center
  }

  .fc-h-event {
      @apply bg-green-600 border-none rounded-lg shadow
  }

  /* custom */

  .button-primary {
    @apply w-full flex items-center justify-center px-6 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-green-600 hover:bg-green-500 focus:outline-none transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10
  }

  .button-secondary {
    @apply w-full flex items-center justify-center px-6 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-black bg-white hover:bg-gray-100 focus:outline-none transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10
  }


  .fc-h-event {
    @apply bg-white
  }
  
  .fc-daygrid-event-harness {
    display: flex;
    text-align: center;
  }

  .fc .fc-daygrid-day-events {
    display: flex;
    text-align: center;
  }

  .fc-daygrid-body .fc-daygrid-body-unbalanced {
    display: flex;
    flex-basis: auto;
    text-align: center;
  }
/* 
  td.fc-daygrid-day.fc-day {
    display: flex;
    flex-basis: auto;
    height: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
  } */

  /* .fc-scrollgrid-sync-table tr {
    display: flex;
    flex-basis: auto;
    height: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
  } */
/*   
  .fc tr {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
  } */

  .fc .fc-scrollgrid-section-body table {
    height: 100% !important; 
  }

  .fc-daygrid-body.fc-daygrid-body-unbalanced {
    height: 100%;
  }